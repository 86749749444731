import { RadioGroup, Switch } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { cx } from "classix";
import { FieldProps } from "formik";
import { useState } from "react";

import { FormHelpText } from "@/components/FormHelpText";
import { FormLabel } from "@/components/FormLabel";

export function Input({
  field,
  form,
  label,
  helpText,
  ...props
}: FieldProps & { label: string; helpText?: string }) {
  const touched = form.touched[field.name];
  const error = (form.errors as Record<string, string>)[field.name];
  const showError = touched && error;
  const valid = touched && !error;

  let borderStyle = "";
  if (showError) {
    borderStyle = "border-error-700";
  } else if (valid) {
    borderStyle = "border-success-600";
  } else {
    borderStyle = "border-neutral-400";
  }

  const style = cx(
    "h-10 px-3 border-2 rounded-md dark:text-white dark:bg-neutral-700 w-full",
    "disabled:bg-neutral-200 dark:disabled:bg-neutral-400 disabled:cursor-not-allowed",
    "focus:ring focus:ring-brand dark:focus:ring-brand-dark focus:outline-none focus:ring-opacity-50",
    borderStyle,
  );
  return (
    <div className="flex flex-col items-start">
      <div className="flex w-full items-baseline justify-between gap-2">
        <FormLabel className="pl-3">{label}</FormLabel>
        {showError && (
          <span className="text-sm text-red-700" aria-live="polite">
            {error}
          </span>
        )}
      </div>
      <input aria-label={label} {...field} {...props} className={style} />
      {helpText !== undefined && <FormHelpText className="mt-1 w-full">{helpText}</FormHelpText>}
    </div>
  );
}

export function SwitchField({
  field,
  form,
  label,
  helpText,
}: FieldProps & { label: string; helpText?: string }) {
  const [checked, setChecked] = useState(() => field.value);

  return (
    <>
      <Switch.Group as={"div"} className="flex items-center">
        <Switch.Label className="w-full pr-4">{label}</Switch.Label>
        <span>
          <Switch
            checked={checked}
            onChange={(value: boolean) => {
              setChecked(!checked);
              form.setFieldValue(field.name, value);
            }}
            className={cx(
              checked ? "bg-brand dark:bg-brand-dark" : "bg-neutral-200 dark:bg-neutral-500",
              "focus:ring-brand dark:focus:ring-brand-dark relative inline-flex h-6 w-11 items-center rounded-full focus:outline-none focus:ring focus:ring-opacity-50",
            )}
          >
            <span
              className={cx(
                checked ? "translate-x-6" : "translate-x-1",
                "inline-block h-4 w-4 rounded-full bg-white transition-transform",
              )}
            />
          </Switch>
        </span>
      </Switch.Group>
      {helpText !== undefined && <FormHelpText>{helpText}</FormHelpText>}
    </>
  );
}

export function RadioGroupField({
  field,
  form,
  label,
  options,
  helpText,
}: FieldProps & {
  label: string;
  options: { label: string; value: string }[];
  helpText?: string;
}) {
  const [value, setValue] = useState(() => field.value);

  return (
    <>
      <RadioGroup
        value={value}
        onChange={value => {
          setValue(value);
          form.setFieldValue(field.name, value);
        }}
      >
        <RadioGroup.Label className="mb-1 text-sm" as="p">
          {label}
        </RadioGroup.Label>
        <div className="space-y-2">
          {options.map(option => (
            <RadioGroup.Option
              key={option.value}
              value={option.value}
              className={({ active, checked }) =>
                cx(
                  "relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none",
                  active ? "ring-brand ring-2 ring-opacity-50" : "",
                  checked ? "bg-brand bg-opacity-75 text-white" : "bg-white dark:bg-neutral-400",
                )
              }
            >
              {({ checked }) => (
                <>
                  <div className="flex w-full items-center justify-between">
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label
                          as="p"
                          className={cx("font-medium", checked ? "text-white" : "text-neutral-900")}
                        >
                          {option.label}
                        </RadioGroup.Label>
                      </div>
                    </div>
                    {checked && (
                      <div className="shrink-0 text-white">
                        <CheckIcon className="h-6 w-6" />
                      </div>
                    )}
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      {helpText !== undefined && <FormHelpText>{helpText}</FormHelpText>}
    </>
  );
}
