import { t } from "@/locales/translate";
import { Field, Form, Formik } from "formik";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";

import { Card } from "@/components/Card";
import CustomLink from "@/components/CustomLink";
import { Input } from "@/components/InputFields";
import { LoadingScreen } from "@/components/LoadingScreen";
import { Title } from "@/components/Title";
import { Button } from "@/components/buttons/Button";
import { useUser } from "@/hooks/useUser";
import { post } from "@/lib/fetch";
import { LoginSchema } from "@/lib/validators";
import { useNotificationsStore } from "@/stores/notificationsStore";

function LoginPage() {
  const nextPage = "/leagues";
  const { isLoading, login } = useUser({
    redirectTo: nextPage,
    redirectIfFound: true,
    shouldRetryOnError: false,
  });
  const showNotification = useNotificationsStore(state => state.showNotification);
  const router = useRouter();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <NextSeo title={t("login.title")} noindex={true} nofollow={true} />
      <div className="mt-16 flex items-center">
        <Card className="mx-auto max-w-lg shadow-2xl">
          <Title className="mx-8 my-8 text-center">{t("login.title")}</Title>
          <Formik
            initialValues={{ username: "", password: "" }}
            validationSchema={LoginSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              post("/api/user/login", values)
                .then(async res => {
                  if (res.ok) {
                    const data = await res.json();
                    await login(data.id);
                    await router.push(nextPage);
                    resetForm();
                  } else {
                    showNotification(await res.text(), res.status);
                  }
                })
                .catch(err => console.error(err))
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting, isValid }) => (
              <Form className="space-y-4 px-4 pb-8">
                <Field
                  required={true}
                  type="text"
                  name="username"
                  placeholder={t("login.form.username")}
                  label={t("login.form.username")}
                  autoComplete="username"
                  component={Input}
                />
                <Field
                  required={true}
                  type="password"
                  name="password"
                  placeholder={t("login.form.password")}
                  label={t("login.form.password")}
                  autoComplete="current-password"
                  component={Input}
                />
                <Button className="w-full" type="submit" disabled={!isValid || isSubmitting}>
                  {t("login.form.submit")}
                </Button>
              </Form>
            )}
          </Formik>
          <p className="mb-4 text-center">
            {t("login.noAccount")}{" "}
            <CustomLink href="/signup">{t("login.noAccountLink")}</CustomLink>
          </p>
          <p className="mb-4 text-center">
            <CustomLink href="/forgot-password">{t("login.forgotPassword")}</CustomLink>
          </p>
        </Card>
      </div>
    </>
  );
}

export default LoginPage;
