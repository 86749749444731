import { t } from "@/locales/translate";
import * as yup from "yup";

export const passwordValidator = yup
  .string()
  .min(6, t("validator.tooShort", { min: 6 }))
  .max(128, t("validator.tooLong", { max: 128 }))
  .required(t("validator.required"));

export const repeatPasswordValidator = passwordValidator.oneOf(
  [yup.ref("password")],
  t("validator.passwordsMustMatch"),
);

export const emailValidator = yup
  .string()
  .email(t("validator.invalidEmail"))
  .required(t("validator.required"));

export const usernameValidator = yup.string().required(t("validator.required"));

export const LoginSchema = yup.object().shape({
  username: usernameValidator,
  password: passwordValidator,
});
export const SignupSchema = yup.object().shape({
  username: usernameValidator,
  email: yup.string().email(t("validator.invalidEmail")),
  password: passwordValidator,
});
export const EmailSchema = yup.object().shape({ email: emailValidator });
export const PasswordSchema = yup.object().shape({ password: passwordValidator });
